<template>
  <div class="card">
    <div
      class="col-xxl-12 card-body p-0 d-flex justify-content-between flex-column"
    >
      <longdo-map
        :zoom="7"
        :lastView="false"
        style="height: 400px"
        @load="loadMap"
        v-if="showMap"
      >
        <longdo-map-marker
          v-for="(item, i) in markers"
          :key="i"
          :location="item.location"
          :title="item.title"
          :detail="item.detail"
        />
      </longdo-map>
    </div>
  </div>
  <!--  ROW 1  -->
  <div class="row g-xl-8 mt-1">
    <div class="col-xxl-6">
      <div class="row g-xl-8">
        <div class="col-xl-6">
          <KTEngageWidget3
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTEngageWidget3>
        </div>

        <div class="col-xl-6">
          <KTEngageWidget4
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTEngageWidget4>
        </div>
      </div>

      <div class="row g-xl-8">
        <div class="col-xl-6">
          <KTEngageWidget5
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTEngageWidget5>
        </div>

        <div class="col-xl-6">
          <KTEngageWidget6
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTEngageWidget6>
        </div>
      </div>
    </div>

    <div class="col-xxl-6">
      <div class="row g-xl-8">
        <div class="col">
          <KTChartWidget1
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTChartWidget1>
        </div>
      </div>
    </div>
  </div>

  <!--  ROW 2  -->
  <div class="row g-xl-8">
    <div class="col-xxl-6">
      <div class="row g-xl-8">
        <div class="col">
          <KTChartWidget2
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTChartWidget2>
        </div>
      </div>
    </div>

    <div class="col-xxl-6">
      <div class="row g-xl-8">
        <div class="col">
          <KTChartWidget3
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTChartWidget3>
        </div>
      </div>
    </div>
  </div>

  <!--  ROW 3  -->
  <div class="row g-xl-8">
    <div class="col-xxl-4">
      <div class="row g-xl-8">
        <div class="col">
          <KTTablesWidget2
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTTablesWidget2>
        </div>
      </div>
    </div>

    <div class="col-xxl-8">
      <div class="row g-xl-8">
        <div class="col">
          <KTTablesWidget3
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTTablesWidget3>
        </div>
      </div>
    </div>
  </div>

  <!--  ROW 4  -->
  <div class="row g-xl-8">
    <div class="col-xxl-4">
      <div class="row g-xl-8">
        <div class="col">
          <KTEngageWidget2
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTEngageWidget2>
        </div>
      </div>
    </div>

    <div class="col-xxl-8">
      <div class="row g-xl-8">
        <div class="col">
          <KTTablesWidget4
            widget-classes="card-xl-stretch mb-5 mb-xl-8"
          ></KTTablesWidget4>
        </div>
      </div>
    </div>
  </div>

  <!--begin::Modals-->
  <!-- <KTCreateAccountModal></KTCreateAccountModal>
  <KTNewCardModal></KTNewCardModal> -->
  <!--end::Modals-->
</template>

<script >
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import KTChartWidget1 from "@/components/widgets/charts/Widget1.vue";
import KTChartWidget2 from "@/components/widgets/charts/Widget2.vue";
import KTChartWidget3 from "@/components/widgets/charts/Widget3.vue";
import KTEngageWidget1 from "@/components/widgets/engage/Widget1.vue";
import KTEngageWidget2 from "@/components/widgets/engage/Widget2.vue";
import KTEngageWidget3 from "@/components/widgets/engage/Widget3.vue";
import KTEngageWidget4 from "@/components/widgets/engage/Widget4.vue";
import KTEngageWidget5 from "@/components/widgets/engage/Widget5.vue";
import KTEngageWidget6 from "@/components/widgets/engage/Widget6.vue";
import KTListWidget1 from "@/components/widgets/lists/Widget1.vue";
import KTListWidget2 from "@/components/widgets/lists/Widget2.vue";
import KTListWidget3 from "@/components/widgets/lists/Widget3.vue";
import KTListWidget4 from "@/components/widgets/lists/Widget4.vue";
import KTSliderWidget1 from "@/components/widgets/sliders/Widget1.vue";
import KTTablesWidget1 from "@/components/widgets/tables/Widget1.vue";
import KTTablesWidget2 from "@/components/widgets/tables/Widget2.vue";
import KTTablesWidget3 from "@/components/widgets/tables/Widget3.vue";
import KTTablesWidget4 from "@/components/widgets/tables/Widget4.vue";
import KTNewCardModal from "@/components/modals/NewCardModal.vue";
import KTCreateAccountModal from "@/components/modals/CreateAccountModal.vue";
import whApi from "@/api/warehouse/";
import { Decode, Encode } from "@/services";
import customerAPI from "@/api/customer/";
import {
  LongdoMapLoad,
  LongdoMap,
  LongdoMapMarker,
  LongdoMapPolyline,
} from "longdo-map-vue";
LongdoMapLoad({
  apiKey: "4ab267dffcde4f9a21701260e783e918",
});

export default {
  name: "dashboard",
  components: {
    KTChartWidget1,
    KTChartWidget2,
    KTChartWidget3,
    KTEngageWidget1,
    KTEngageWidget2,
    KTEngageWidget3,
    KTEngageWidget4,
    KTEngageWidget5,
    KTEngageWidget6,
    KTListWidget1,
    KTListWidget2,
    KTListWidget3,
    KTListWidget4,
    KTSliderWidget1,
    KTTablesWidget1,
    KTTablesWidget2,
    KTTablesWidget3,
    KTTablesWidget4,
    KTNewCardModal,
    KTCreateAccountModal,
  },

  data: () => ({
    markers: [],
    showMap: false,
    loading: false,
    customerData: [],
  }),
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dashboard", ["Dashboards"]);
    });
    document.title = "CHOMTHANA | Warehouses";
  },
  created() {
    this.getAllCustomer();
  },
  methods: {
    async getAllCustomer() {
      this.loading = true;
      let branchId = localStorage.getItem("branchId");
      let companyId = localStorage.getItem("companyId");
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getAll(branchId, companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.customerData = responseData.data;
        this.customerData.forEach((element, index) => {
          element.no = index + 1;
          if (element.lat && element.lng) {
            this.markers.push({
              location: {
                lon: parseFloat(element.lng),
                lat: parseFloat(element.lat),
              },
              title: element.code,
              detail: "ชื่อลูกค้า: " + element.name,
            });
          }
        });
        this.showMap = true;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    loadMap(map) {
      map.Layers.setBase(longdo.Layers.NORMAL);
    },
  },
};
</script>
